import React, { useState } from "react"
import { useForm } from 'react-hook-form';
import { Link } from 'gatsby'
import Checkmark from "../images/Checkmark.png";
import EyeShow from "../images/EyeShow.png";
import EyeHide from "../images/EyeHide.png";
import Layout from "../components/layout"
import styled, { css } from "styled-components"
import SEO from "../components/seo"

const Input = styled.input`
  &::placeholder { 
    color: #b9b9b9;
    opacity: 1; 
  }
  width: 90%;
  color: #121212;
  height: 25px; 
  border-radius: 2px;
  background-color: #ffffff;
  font-size: 14px;
  padding: 8px 16px;
  border-radius:1.5px;
  margin-right: 30px;
  border:1px solid #e2e2e2;
  font-family: "Yantramanav";
`


const CheckBox = styled.div`
    height: 16px; 
    width: 16px;
    border: 1px solid black;
    margin-right: 20px; 
    border-radius: 2px;
    background-color: transparent;
    
    ${props => props.rememberMe && css`
        background-color: #0077af;
        border-color: #0077af
    `};
`

function LoginPage({ location }) {
    const { register, handleSubmit, errors } = useForm();
    const [rememberMe, toggleRememberMe] = useState(false);
    const [showPassword, toggleShowPassword] = useState(false);

    const submit = () => {
        console.log("logged in!");
    }

    return (
        <Layout path={`${location.pathname}`}>
            <SEO title="Login" />
            <section style={{ "padding": "100px 6.5% 150px", width: "1024px", "margin": "0 auto" }}>
                <h1 style={{ "fontFamily": "Yantramanav", "fontSize": "32px", paddingTop: "16px" }}>Login</h1>
                <h2 style={{ "fontFamily": "Yantramanav", "fontSize": "18px", "fontWeight": "400", "color": "#5e5e5e", marginBottom: "56px" }}>
                    Making payments to Juristerra has never been easier, login now so we can automatically start working on your projects.
                </h2>
                <h2 style={{ "fontFamily": "Yantramanav", "fontSize": "18px", "fontWeight": "400", "color": "#5e5e5e", marginBottom: "56px" }}>
                    Login to V3 Juristerra <a href={'http://v3.juristerra.net/Account/Login'}>here</a>
                </h2>
            </section>
        </Layout >
    );
}


// original login form that should be placed after h2 tag
// <form
//     style={{ marginTop: "31px" }}
//     onSubmit={handleSubmit(submit)}
// >
//     <div>
//         <label style={{ fontFamily: "Yantramanav", "fontSize": "16px", "fontWeight": "500", marginBottom: "8px" }}>
//             Email
//         </label>
//         <br />
//         <Input
//             name='email'
//             placeholder='Email (required)'
//             ref={register({ required: true })}
//         />
//         {errors.email && <span style={{ fontFamily: "Yantramanav", fontSize: "14px", color: "#dc3545" }}>Please type your email</span>}
//     </div>
//     <div style={{ marginTop: "31px" }}>
//         <label style={{ fontFamily: "Yantramanav", "fontSize": "14px", "fontWeight": "500" }}>
//             Password
//         </label>
//         <br />
//         <div style={{ position: "relative" }}>
//             <Input
//                 // style={{ fontFamily: "Yantramanav", "fontSize": "14px", width: "90%", padding: "8px 16px", border: "1px solid #b9b9b9", borderRadius: "1.5px" }}
//                 name='password'
//                 type={showPassword ? 'text' : 'password'}
//                 placeholder='Password (required)'
//                 ref={register({ required: true })}
//             />
//             {showPassword ? <img src={EyeHide} style={{ position: "absolute", top: "5px", right: "25px" }} onClick={() => toggleShowPassword(false)} /> : <img src={EyeShow} style={{ position: "absolute", top: "5px", right: "25px" }} onClick={() => toggleShowPassword(true)} />}
//         </div>
//         {errors.password && <span style={{ fontFamily: "Yantramanav", fontSize: "14px", color: "#dc3545" }}>Please type your password</span>}
//     </div>
//     <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "16px", fontFamily: "Yantramanav", fontSize: "14px" }} onClick={() => toggleRememberMe(!rememberMe)}>
//         <div style={{ display: "flex", alignItems: "center" }}>
//             <CheckBox rememberMe={rememberMe}>
//                 {rememberMe && <img src={Checkmark} />}
//             </CheckBox>
//             Remember me
//         </div>
//         <Link id="on-hover-link" style={{ color: "#121212" }}>
//             Forgot password?
//         </Link>
//     </div>
//     <input
//         style={{
//             width: "127px",
//             backgroundColor: "#0077af",
//             padding: "8px 0",
//             fontFamily: "Yantramanav",
//             fontSize: "14px",
//             fontWeight: "500",
//             color: "white",
//             border: "1px solid #0077af",
//             borderRadius: "1.5px",
//             marginTop: "33px"
//         }}
//         type='submit'
//         value='Login'
//     />
// </form>

export default LoginPage;
